/// Adds a browser prefix to the property
/// @param {*} $property Property
/// @param {*} $value Value

@mixin css3-prefix($property, $value) {
	-webkit-#{$property}: #{$value};
	-khtml-#{$property}: #{$value};
	-moz-#{$property}: #{$value};
	-ms-#{$property}: #{$value};
	-o-#{$property}: #{$value};
	#{$property}: #{$value}; }

/// Border Radius
/// @param {Size} $radius [5px] - Radius
/// @require {mixin} css3-prefix

@mixin border-radius($radius: 5px) {
	@include css3-prefix('border-radius', $radius); }

/// Border Radius Separate
/// @param {Size} $topLeftRadius [5px] - Top Left
/// @param {Size} $topRightRadius [5px] - Top Right
/// @param {Size} $bottomLeftRadius [5px] - Bottom Left
/// @param {Size} $bottomRightRadius [5px] - Bottom Right

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
	-webkit-border-top-left-radius: $topLeftRadius;
	-webkit-border-top-right-radius: $topRightRadius;
	-webkit-border-bottom-right-radius: $bottomRightRadius;
	-webkit-border-bottom-left-radius: $bottomLeftRadius;
	-moz-border-radius-topleft: $topLeftRadius;
	-moz-border-radius-topright: $topRightRadius;
	-moz-border-radius-bottomright: $bottomRightRadius;
	-moz-border-radius-bottomleft: $bottomLeftRadius;
	border-top-left-radius: $topLeftRadius;
	border-top-right-radius: $topRightRadius;
	border-bottom-right-radius: $bottomRightRadius;
	border-bottom-left-radius: $bottomLeftRadius; }


/// Generic Transform
@mixin transform($transforms) {
	@include css3-prefix('transform', $transforms); }

/// Rotate
/// @param {$deg} Degree
@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg)); }

/// Scale
/// @param {$scale}
@mixin scale($scale) {
	@include transform(scale($scale)); }

/// Translate
/// @param {$x}
/// @param {$y}
@mixin translate($x, $y) {
	@include transform(translate($x, $y)); }

// skew
@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg)); }

//transform origin
@mixin transform-origin($origin) {
	moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin; }















@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	-o-box-shadow: $shadow;
	box-shadow: $shadow; }



@mixin scale($scale: 1.15) {
	transform:          scale($scale);
	-webkit-transform:  scale($scale);
	-moz-transform:     scale($scale);
	-ms-transform:      scale($scale);
	-o-transform:       scale($scale); }

@mixin verticalAlign($position: relative) {
	position: $position;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%); }

@mixin transition($property: all, $duration: 0.25s, $timing-function: ease-in-out) {
	transition:         $property $duration $timing-function;
	-webkit-transition: $property $duration $timing-function;
	-moz-transition:    $property $duration $timing-function;
	-ms-transition:     $property $duration $timing-function;
	-o-transition:      $property $duration $timing-function; }

@mixin linearGradient($top, $bottom) {
	background: $top;
	background: -moz-linear-gradient(top,  $top 0%, $bottom 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom));
	background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%);
	background: -o-linear-gradient(top,  $top 0%,$bottom 100%);
	background: -ms-linear-gradient(top,  $top 0%,$bottom 100%);
	background: linear-gradient(to bottom,  $top 0%,$bottom 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); }

