/* COLORS */
$shopping_cart_row_active: #3ab54a;
$shopping_cart_row: #eaeaea;

$button-first-bg: #3ab54a;
$button-second-bg: transparent;

$button-first-font: white;
$button-second-font: #3ab54a;

$primary-color: #3ab54a;

$border-radius: 50px;

$box-shadow: 0 0 10px rgba(0,0,0,.1);

$left-menu-heading-color: #3ab54a;
$left-menu-background: #f8f8f8;
$left-menu-border: #dadada;
$left-menu-link-color: #404041;


$product-name-color: #404041;
$product-short-content-color: #404041;

$product-detail-vlastnost: #747474;
$product-detail-ceny: #c4c4c4;

$cena-bezna: #404041;
$cena-aktualna: #404041;

$skladom: #3ab54a;
$neskladom: red;
$buy-btn-background-color: #3ab54a;
$buy-btn-font-color: white;

$tab-background: #f8f8f8;
$tab-font-color: #404041;

$breadcrumb-background-color: #f8f8f8;
$breadcrumb-font-color: #404041;


/* PRVKY */

.btn-first {
	background-color: $button-first-bg;
	color: $button-first-font;
	border: 2px solid transparent;
	border-radius: $border-radius;
	&:hover, &:active, &:focus {
		background-color: $button-second-bg;
		color: $button-second-font;
		border: 2px solid $button-first-bg; } }
.btn-second {
	background-color: $button-second-bg;
	color: $button-second-font;
	border: 2px solid $button-first-bg;
	border-radius: $border-radius;
	&:hover, &:active, &:focus {
		background-color: $button-first-bg;
		color: $button-first-font;
		border: 2px solid transparent; } }

/* MENU - KOSIK - HEADER */
.cartcena, .cartkusy {
	color: white; }

/* KATALOG PRODUKTOV */

#wait {
	background-color: rgba(255,255,255,0.5);
	position: absolute;
	z-index: 999;
	width: 100%;
	height: 100%;
	i {
		color: $primary-color;
		margin: 20% auto;
		display: block; } }

/* FILTER */

.fulllink {
	cursor: pointer; }
.filter-second-part {
	margin-top: 25px;
	margin-bottom: 25px; }
.filter-heading {
	background-color: $gray;
	padding: 14px;
	color: #fff;
	font-size: 18px; }
.filter-wrapper {
	padding-top: 25px;
	padding-bottom: 15px;
	border: 1px solid $border-color;
	label {
		font-size: 12px; } }
.filter-input, .filter-select {
	border-radius: $border-radius;
	border: 1px solid $border-color; }
.fulllink {
	button {
		margin: 4px;
		float: right;
		&:before {
			font-family: FontAwesome;
			content: "\f068"; } } }
.fulllink.collapsed {
	button {
		&:before {
			font-family: FontAwesome;
			content: "\f067"; } } }

#priceslider {
	margin-top: 11px; }
.noUi-horizontal {
	height: 10px; }
.noUi-handle {
	top: -10px !important;
	border-radius: 50px;
	width: 30px !important;
	height: 30px !important;
	box-shadow: none;
	&:before, &:after {
		background: transparent; } }
.noUi-connect {
	background-color: $primary-color; }

.noUi-handle-lower {}

.noUi-handle-upper {}

.filter-manufacturers, .filter-tags {
	margin-top: 25px;
	margin-bottom: 0px;
	background-color: white;
	padding-top: 10px;
	padding-bottom: 10px;
	.checkbox {
		margin-top: 0px !important;
		margin-bottom: 0px !important; } }
.filter-tags {
	margin-top: 0px !important; }
.filter-tagy {
	border-top: 1px $gray solid;
	margin-bottom: 15px; }
.filter-buttons {
	button {
		margin-right: 10px; } }
input[type=checkbox] {
	-ms-transform: scale(0.7) /* IE */;
	-moz-transform: scale(0.7) /* FF */;
	-webkit-transform: scale(0.7) /* Safari and Chrome */;
	-o-transform: scale(0.7) /* Opera */; }
.tag-label {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 2px;
	padding-bottom: 2px;
	border-radius: $border-radius;
	font-weight: 700; }



/* VYPIS PRODUKTOV */

.product-wrap, .products-wrapper {
	padding-bottom: 80px; }
.product {
	text-align: center;
	img {
		width: auto;
		height: auto;
		max-height: 300px;
		margin: 0 auto;
		display: block; } }
.productname {
	color: $product-name-color;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 5px;
	height: 50px;
	overflow: hidden; }
.category {
	background-color: $primary-color;
	color: white;
	border-radius: $border-radius;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 13px;
	width: auto;
	display: inline-block;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 2px;
	padding-bottom: 2px; }
.productshort {
	font-weight: 300;
	font-size: 16px;
	color: $product-short-content-color;
	margin-bottom: 5px;
	height: 50px;
	overflow: hidden; }
.skladom, .neskladom {
	font-weight: 700;
	font-size: 15px;
	margin-bottom: 0px; }
.skladom {
	color: $skladom; }
.neskladom {
	color: $neskladom; }
.beznacena {
	color: $cena-bezna;
	margin-bottom: 0px; }
.nasacena {
	color: $cena-aktualna;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 0px; }
.buy-btn {
	background-color: $buy-btn-background-color;
	min-width: 125px;
	width: auto;
	height: 55px;
	color: white;
	border: 2px solid transparent;
	font-size: 15px;
	border-radius: $border-radius;
	transition: $transition;
	font-weight: 700;
	text-transform: lowercase;
	padding: 5px 10px;
	cursor: pointer;
	display: block;
	margin: 0 auto;
	&:hover, &:active, &:focus {
		color: $primary-color;
		background-color: transparent;
		border: 2px solid $primary-color;
		transition: $transition;
		text-decoration: none; } }

/* PRODUCT DETAIL */

.product-detail-wrapper {
	padding-top: 50px;
	padding-bottom: 50px; }
.product-detail-name {
	color: $primary-color;
	font-weight: 700;
	padding-top: 15px;
	padding-bottom: 20px; }
.product-detail-image {
	border: 1px solid $border-color; }
.product-detail-info {
	border: 1px solid $border-color;
	padding-top: 10px;
	padding-bottom: 10px; }
.product-gallery {
	> div {
		> div {
			padding: 5px 5px 0px 0px; } } }

.product-detail-info-row {
	margin-bottom: 5px;
	color: $product-detail-vlastnost;
	font-weight: 300; }
.product-detail-price-row {
	margin-bottom: 5px;
	color: $product-detail-ceny;
	font-weight: 300; }
.product-detail-price-main-row {
	color: black;
	font-weight: 700; }
.main-price {
	color: $primary-color; }
/* Pridavanie do kosika - form cast */
.product-form {
	width: 100%;
	display: inline-block; }
/* END */
.product-detail-input {
	border-radius: $border-radius; }
.product-tag {
	display: inline-block;
	color: white;
	padding: 5px 10px;
	border-radius: $border-radius;
	margin-bottom: 10px; }
/* DETAIL PRODUKTU - TABY */
.product-detail-tabs {
	padding-top: 30px;
	padding-bottom: 10px;
	li {
		transition: $transition;
		a {
			width: auto;
			background-color: $tab-background;
			padding: 15px 25px;
			border: 1px solid $border-color;
			color: $tab-font-color;
			font-weight: 700;
			font-size: 16px;
			transition: $transition;
			&:hover {
				background-color: white;
				border-bottom: 1px solid transparent;
				font-size: 18px;
				transition: $transition; } }
		.active {
			background-color: white;
			border-bottom: 1px solid transparent;
			font-size: 18px;
			transition: $transition; } } }
.tab-content {
	border: 1px solid $border-color;
	padding: 15px;
	> div {
		padding-top: 15px;
		padding-bottom: 15px; } }

/* BREADCRUMB - OMRVINKOVE MENU */

.breadcrumb {
	background-color: $breadcrumb-background-color;
	color: $primary-color;
	a, span {
		color: $breadcrumb-font-color;
		padding-left: 10px;
		padding-right: 10px; }
	.actual {
		font-weight: 700;
		color: $primary-color; } }

/* LAVE MENU */
.left-menu-heading {
	background-color: $left-menu-heading-color;
	padding: 14px;
	color: white;
	font-size: 18px;
	margin-bottom: 0px;
	&:before {
		font-family: FontAwesome;
		content: "\f0c9";
		padding-right: 10px; } }
.leftmenu {
	background-color: $left-menu-background;
	ul {
		list-style: none; }
	a {
		&:hover {
			color: $primary-color;
			text-decoration: none; } }
	> ul {
		padding: 0 10px;
		> li {
			padding-top: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid $left-menu-border;
			> a {
				font-weight: 700;
				color: $left-menu-link-color;
				&:before {
					content: "+";
					display: inline-block;
					margin-right: 10px; } }
			ul {
				li {
					a {
						color: $left-menu-link-color;
						font-weight: 400; } } } } } }



/* NAKUPNY KOSIK */

/* HORNE MENU V KOSIKU */
.navigation-bar-basket {
	a,div {
		transition: $transition; }
	a:hover, a:active, a:focus, div:hover, div:active, div:focus {
		transition: $transition; }
	a {
		font-size: 20px;
		color: black;
		padding: 14px;
		display: block;
		font-weight: 300; }
	.step {
		background-color: $shopping_cart_row;
		&:hover, &:active, &:focus {
			background-color: $shopping_cart_row_active;
			a {
				text-decoration: none;
				color: white; } }
		@media (min-width: 768px) {
			&:first-child {
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius; }
			&:last-child {
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius; } } }
	.active {
		background-color: $shopping_cart_row_active;
		/*  "Zobacik"  */
		//@media (min-width: 768px)
		//:after
		//content: ""
		//border-left: 10px solid transparent
		//border-right: 10px solid transparent
		//border-top: 10px solid $shopping_cart_row_active
		//position: absolute
		//margin-left: auto
		//margin-right: auto
		//left: 0
		//right: 0
		//width: 10px
		a {
			font-weight: 700;
			color: white; } } }

/* VYPIS PRODUKTOV V KOSIKU A WISHLISTE */

.main-basket {
	padding-bottom: 50px;
	input {
		border-radius: $border-radius; }
	h2, h3 {
		background-color: $primary-color;
		padding: 14px;
		color: white;
		border-radius: $border-radius; } }

.pointer {
	cursor: pointer; }

.cart-product {
	padding-bottom: 10px;
	margin-bottom: 20px;
	border-bottom: 1px solid $border-color;
	.cart-product-image {
		max-height: 100px;
		display: block;
		margin: 0 auto;
		&:hover {
			box-shadow: $box-shadow; } }
	.cart-product-name {
		color: black;
		font-weight: 700;
		display: block;
		&:hover {
			color: $primary-color;
			text-decoration: none; } }
	form {
		width: 100%; }
	input {
		display: inline-block;
		width: 80px;
		border-radius: $border-radius;
		margin: 0px 15px; }
	button {
		margin: 0px 5px; } }


/* VOUCHRE */

.voucher {
	input {
		width: auto;
		display: inline-block; } }

/* STRANKOVANIE */

/* VYUZITE V AJAXE */
.strankovanie {
	list-style: none;
	li {
		display: none;
		margin: 5px;
		padding: 5px 10px;
		background-color: transparent;
		border: 1px solid $primary-color;
		border-radius: $border-radius;
		color: $primary-color;
		cursor: pointer; }
	.active {
		font-weight: 700;
		display: inline-block;
		background-color: $primary-color;
		border-radius: $border-radius;
		color: white; }
	.page {
		display: inline-block; } }

/* KLASICKE STRANKOVANIE */

.pagination {
	li {
		&:first-child {
			a {
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius; } }
		&:last-child {
			a {
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius; } }
		a {
			background-color: white;
			border: 2px solid $primary-color;
			color: $primary-color;
			border-radius: $border-radius;
			padding: 5px 10px;
			&:hover {
				background-color: $primary-color;
				color: white;
				border: 2px solid $primary-color;
				box-shadow: none; } } }

	.current {
		background-color: transparent;
		&:first-child {
			span {
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius; } }
		&:last-child {
			span {
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius; } }
		span {
			background-color: $primary-color;
			border: 2px solid $primary-color;
			color: white;
			border-radius: $border-radius;
			padding: 5px 10px;
			&:hover {
				background-color: $primary-color;
				border: 2px solid $primary-color;
				color: white; } } } }
