.translate-button {
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color .25s ease-out,color .25s ease-out;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: .85em 1em;
  margin: 1rem 0 1rem;
  font-size: .9rem;
  background-color: #ed6c08;
  color: #fefefe;
  text-transform: uppercase; }

.translate-button:hover {
  background-color: #c95c07;
  color: #fefefe; }

/* BASIC */

html, body, h1, h2, h3, h4, h5, h6, a, p, span, input, textarea, select, option, div {
  font-family: $font2; }
a, button {
  &:hover, &:active, &:focus {
    text-decoration: none; } }
.border {
  border: 1px solid $border-color; }
.nopad {
  padding: 0;
  margin: 0; }
.simpleflex {
  display: flex;
  flex-wrap: wrap; }
.simpleflex-item {
  align-self: center; }
.img-center {
  margin: 0 auto;
  display: block; }
.text-r {
  width: 100%;
  text-align: right; }

html, body {
  font-family: $font2 !important; }

.logo {
  width: 320px;
  margin: 20px 0; }

.navbar {
  padding: 0; }

.navbar-nav {
  margin: 0 auto;
  li {
    a {
      &:hover {
        background-color: #2bc5dd;
        color: #ffffff; } }
    a {
      font-size: 13px;
      color: #3a3a3c;
      padding: 44px 15px 42px; } }
  .active {
    a {
      background-color: #2bc5dd;
      color: #ffffff;
      padding: 44px 15px 42px; } } }

.navbar-toggler {
  border-color: rgb(31,159,179) !important; }

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(58,58,60, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }

.panel_narrow {
  display: none; }

.lang_icon {
  width: 15px; }

.arrow_icon {
  width: 10px; }

.languages {
  cursor: pointer; }

.code {
  display: none;
  position: absolute; }

#current_lang {
  display: inline-block;
  margin: 0 10px 0 15px;
  font-size: 13px;
  color: #343027; }

.header_wrap {
  .row {
    width: 100%;
    margin: 0; } }

.header {
  background-image: url("../web/header_bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  width: 100%;
  h1 {
    margin: 0 0 0 49%;
    display: flex;
    align-items: center;
    height: 450px;
    font-size: 60px;
    line-height: 60px;
    font-weight: bold;
    color: #3a3a3c; } }

.header_alter {
  background-image: url("../web/header_podstranka_s.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%; }

.blue_boxes_wrap {
  background-color: #d4d4d4;
  height: 150px;
  width: 100%;
  .row {
    width: 100%; }
  .col-md-4 {
    padding-left: 0;
    padding-right: 0;
    float: left; }
  .blue_box {
    height: 150px;
    .bb_left {
      background-color: #3a3a3c;
      float: left;
      height: 100%;
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      .services_icon {
        width: 50px; } }
    .bb_right {
      background: linear-gradient(to top right, #24afc5 , #1a8ea0);
      height: 100%;
      width: 65%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      h2 {
        font-size: 24px;
        line-height: 25px;
        font-weight: bold;
        color: #ffffff;
        margin: 0; } }
    &:hover {
      .bb_right {
        background: #282828; }
      //.bb_right
      ////h2
 } } }      ////  color: #3a3a3c

.main_page_wrap {
  margin: 0 0 60px;
  .row {
    justify-content: center; }
  h2 {
    font-size: 50px;
    line-height: 40px;
    font-weight: bold;
    color: #1a8ea0;
    margin: 50px 0 60px 0;
    text-align: center; }
  .home_text {
    font-size: 17px;
    line-height: 30px;
    color: #3a3a3c;
    margin: 0 auto 60px;
    p {
      margin: 0; } }
  .mp_left {
    background-color: #2bc5dd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 320px;
    h3 {
      font-size: 30px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
      margin: 0; }
    h4 {
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      font-weight: bold;
      margin: 25px 0 0 0; }
    a {
      font-size: 17px;
      line-height: 30px;
      font-weight: bold;
      color: #2bc5dd;
      background-color: #ffffff;
      padding: 25px 40px;
      margin: 35px 0 0 0;
      &:hover {
        color: #ffffff;
        background-color: #3a3a3c; } }
    p {
      margin: 0; } }

  .mp_right {
    background-color: #f8f8f8;
    height: 320px;
    padding: 35px 30px;
    display: flex;
    align-items: center;
    margin: 0 0 0 30px;
    h1, h2, h3, h4, h5, h6, p, ul, li {
      margin: 0; }
    ul {
      list-style-type: none;
      padding: 0 0 0 16px; }
    li {
      &:before {
        content: '';
        height: 4px;
        width: 4px;
        background-color: #2bc5dd;
        border-radius: 50%;
        display: inline-block;
        margin: 0 10px 3px 0; } } } }

.footer_wrap {
  background-color: #3a3a3c;
  width: 100%;
  text-align: center;
  padding: 0 0 30px; }

.footer_logo {
  width: 300px;
  margin: 30px 0 40px; }

.footer_info {
  h4 {
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    font-weight: bold;
    margin: 0 0 25px; }
  a {
    font-size: 13px;
    line-height: 25px;
    color: #ffffff;
    font-weight: 300;
    margin: 0;
    display: block;
    &:hover {
      color: #2bc5dd; } } }

.footer_address {
  font-size: 13px;
  line-height: 25px;
  p {
    font-size: 13px;
    line-height: 25px; } }

.footer_contact {
  a {
    display: inline-block; }
  span {
    font-size: 13px;
    line-height: 25px;
    font-weight: 300; } }

.footer_downtown {
  background-color: #242424;
  width: 100%;
  height: 82px;
  h5 {
    position: absolute;
    font-size: 13px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    height: 82px;
    margin: 0;
    font-weight: 300;
    right: 0;
    a {
      font-weight: 700;
      color: #ffffff;
      &:hover {
        color: #2bc5dd; } } }
  p {
    display: block;
    text-align: center;
    font-size: 13px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    height: 82px;
    font-weight: 300;
    margin: 0;
    position: absolute;
    left: 0;
    b {
      font-weight: 700; }
    a {
      color: #ffffff;
      &:hover {
        color: #2bc5dd; } } } }

.fd_info {
  position: relative; }

.sixnet_logo {
  width: 70px; }

// KONTAKT
.contact_info_wrap {
  margin: 30px 0;
  a {
    color: #2bc5dd;
    &:hover {
      color: #3a3a3c; } } }

// CONTENT
ul#lang.code a:before {
  text-transform: lowercase !important; }

.content-web {
  ul {
    list-style-type: none;
    padding: 0 0 0 16px;
    li {
      &:before {
        content: '';
        height: 4px;
        width: 4px;
        background-color: #2bc5dd;
        border-radius: 50%;
        display: inline-block;
        margin: 0 10px 3px 0; } } }
  a {
    color: #2bc5dd;
    &:hover {
      color: #3a3a3c; } } }

.contact_clause {
  color: #2bc5dd;
  &:hover {
    color: #3a3a3c; } }

@media screen and (max-width: 1300px) {
  .header {
    h1 {
      margin: 0 0 0 40%; } } }

@media screen and (max-width: 1199px) {
  .logo {
    width: 290px; } }

@media screen and (max-width: 991px) {
  .header {
    h1 {
      margin: 0 0 0 20%; } }

  .panel_narrow {
    display: block; }

  .panel_wide {
    display: none; }

  .navbar-nav {
    text-align: center;
    li {
      padding: 12px 0;
      a {
        padding: 5px 15px;
        &:hover {
          background-color: #2bc5dd;
          color: #ffffff;
          padding: 5px 15px; } } }
    li:last-child {
      padding: 12px 0 24px; }
    .active {
      a {
        padding: 5px 15px; } } }

  .lang_btn {
    position: absolute;
    right: 60px;
    top: 35px; }

  .custom-toggler {
    position: absolute;
    right: -20px;
    top: 30px; }

  .main_page_wrap {
    .mp_right, .mp_left {
      height: 370px; } }

  .changable_fluid {
    padding-left: 0;
    padding-right: 0; }

  .blue_boxes_wrap {
    height: 90px;
    .container {
      max-width: 100%; }
    .blue_box {
      height: 90px;
      .bb_left {
        width: 20%;
        .services_icon {
          width: 30px; } }
      .bb_right {
        width: 80%;
        h2 {
          font-size: 22px;
          line-height: 20px; } } } } }

@media screen and (max-width: 767px) {
  .logo {
    width: 220px; }

  .header {
    h1 {
      font-size: 50px;
      line-height: 50px;
      margin: 0 0 0 0; } }

  .blue_boxes_wrap {
    .blue_box {
      .bb_right {
        h2 {
          font-size: 18px;
          line-height: 18px; } } } }

  .footer_downtown {
    height: auto;
    h5 {
      position: static;
      height: 50px; }
    p {
      height: 50px; }
    p {
      position: static; } }

  .lang_btn {
    top: 28px; }

  .custom-toggler {
    top: 22px; }

  .main_page_wrap {
    .mp_right {
      margin: 0;
      height: auto; }
    .mp_left {
      height: 320px; } } }

@media screen and (max-width: 575px) {
  .lang_btn {
    right: 80px; }

  .custom-toggler {
    right: 0px; }

  .header {
    h1 {
      font-size: 40px;
      line-height: 40px;
      margin: 0 0 0 5%; } }

  .blue_boxes_wrap {
    height: auto; }

  .main_page_wrap {
    h2 {
      font-size: 40px;
      line-height: 30px; }
    .mp_right {
      height: auto; } }

  .footer_info {
    margin: 0 0 20px;
    h4 {
      margin: 0 0 5px; } }

  .footer_downtown {
    p {
      margin: 10px 0 20px;
      display: block;
      height: auto; } }

  .contact_content {
    margin: 20px 0 0 0; } }

@media screen and (max-width: 420px) {
  .logo {
    width: 120px; }

  .lang_btn {
    top: 20px; }

  .custom-toggler {
    top: 15px; }

  .header {
    h1 {
      font-size: 30px;
      line-height: 30px;
      margin: 0 0 0 2%; } } }
